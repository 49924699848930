import React, { useState } from "react"

import Seo from "../components/seo"

import useMediaQuery from "@mui/material/useMediaQuery"
import { Button, TextField, Typography, Divider } from "@mui/material"
import { useTheme } from "@mui/material/styles"

import CustomSnackBar from "../components/SnackBar"
import GoogleMap from "../components/GoogleMap"
import Layout from "../components/layout"
import SectionTitle from "../components/SectionTitle"
import { PhoneIcon, MailIcon } from "../helpers/Icons"
import { theme, palette, pageSetting } from "../helpers/theme"

const validateEmail = email => {
  const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  return regex.test(email)
}

const Form = () => {
  const [title, setTitle] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [snackBar, setSnackBar] = useState(false)
  const [snackMsg, setSnackMsg] = useState(null)

  const isValid = (email, title, message) => {
    if (!title) return { msg: "Brak tematu", err: true }
    else if (!validateEmail(email))
      return { msg: "Twój email jest nieprawidłowy", err: true }
    else if (message.length < 20)
      return {
        msg: "Twoja wiadomość powinna być dłuższa niż 20 znaków",
        err: true,
      }
    else return { err: false }
  }

  const handleTitleChange = e => setTitle(e.target.value)
  const handleEmailChange = e => setEmail(e.target.value)
  const handleMessageChange = e => setMessage(e.target.value)
  const handleSubmit = e => {
    const err = isValid(email, title, message)
    if (err.err) {
      e.preventDefault()
      setSnackBar(true)
      setSnackMsg(err.msg)
    }
  }
  const closeSnackBar = (e, reason) => {
    if (reason === "clickaway") return

    setSnackBar(false)
    setSnackMsg(null)
  }

  return (
    <form
      action="https://formspree.io/f/xzbozeon"
      method="POST"
      id="form"
      onSubmit={handleSubmit}
    >
      <div css={styles.form}>
        <TextField
          css={styles.input}
          type="text"
          value={title}
          fullWidth
          onChange={handleTitleChange}
          id="inputSubject"
          placeholder="Temat"
        />
        <TextField
          css={styles.input}
          type="email"
          fullWidth
          value={email}
          id="inputEmail"
          onChange={handleEmailChange}
          name="_replyto"
          placeholder="email@gmail.com"
        />
        <TextField
          css={styles.TextArea}
          id="inputMessage"
          fullWidth
          name="message"
          multiline
          rows={12}
          onChange={handleMessageChange}
          placeholder="Wiadomość"
          value={message}
        />
      </div>
      <Button
        css={{ marginTop: theme.gutterBig }}
        variant="contained"
        type="submit"
      >
        Wyślij
      </Button>
      <CustomSnackBar
        text={snackMsg}
        open={snackBar}
        onClose={closeSnackBar}
        variant={"info"}
      />
    </form>
  )
}

const TitleContact = ({ children }) => (
  <>
    <Typography css={{ fontFamily: pageSetting.text.fontFamily }} variant="h5">
      {children}
    </Typography>
    <Divider
      sx={{ background: pageSetting.attention, width: "80%", marginBottom: 1 }}
    />
  </>
)

const displaySet = [
  { text: "Biuro Rachunkowe Pro Calculo", props: {} },
  { text: "ul. Grunwaldzka 23 Grybów", props: {} },
  { text: "Czynne pn-pt 8:00 - 15:00", props: {} },
]

const TextContact = ({ children }) => (
  <>
    <Typography
      css={{
        fontFamily: pageSetting.text2.fontFamily,
      }}
      variant="subtitle1"
    >
      {children}
    </Typography>
  </>
)

const CustomButton = ({ icon, style, size, href, children }) => (
  <Button
    sx={{
      alignSelf: "baseline",
      textTransform: "lowercase",
      fontFamily: pageSetting.text2.fontFamily,
      paddingTop: 0,
    }}
    size={size || "small"}
    href={href}
    startIcon={icon}
  >
    {children}
  </Button>
)

const ContactBox = ({ screen }) => (
  <div css={styles.contactRoot(screen)}>
    <div css={styles.innerBox}>
      <TitleContact>Nasz Adres</TitleContact>
      {displaySet.map(el => (
        <TextContact key={el.text}>{el.text}</TextContact>
      ))}
    </div>
    <div css={styles.innerBox}>
      <TitleContact>Email</TitleContact>
      <CustomButton
        size="large"
        href="mailto:procalculo.biuro@gmail.com"
        icon={<MailIcon />}
      >
        procalculo.biuro@gmail.com
      </CustomButton>
    </div>
    <div css={styles.innerBox}>
      <TitleContact>Telefon</TitleContact>
      <Typography css={{ fontFamily: "inherit" }} variant="subtitle1">
        Księgowość
      </Typography>
      <CustomButton size="large" href="tel:183000382" icon={<PhoneIcon />}>
        (18) 3000 382
      </CustomButton>
      <CustomButton size="large" href="tel:517492682" icon={<PhoneIcon />}>
        517 492 682
      </CustomButton>
      <Typography css={{ fontFamily: "inherit" }} variant="subtitle1">
        Ubezpieczenia
      </Typography>
      <CustomButton size="large" href="tel:731793193" icon={<PhoneIcon />}>
        731 793 193
      </CustomButton>
    </div>
  </div>
)

const Contact = () => {
  const themeMui = useTheme()
  const matchesDownMd = useMediaQuery(themeMui.breakpoints.down("lg"))

  return (
    <Layout>
      <Seo title="Kontakt" />
      <div css={styles.root}>
        <SectionTitle title="kontakt" />
        <Form />
      </div>
      <ContactBox screen={matchesDownMd} />
      <GoogleMap
        title="Mapa naszej lokalizacji"
        style={{ marginTop: theme.gutterBig * 10 }}
      />
    </Layout>
  )
}

const styles = {
  root: {
    marginTop: theme.gutterBig * 6,
  },
  input: {
    marginTop: theme.gutterMedium,
    width: "90%",
    boxShadow: `0 0 2px ${palette.darkGrey}`,
  },
  TextArea: {
    marginTop: theme.gutterMedium,
    boxShadow: `0 0 2px ${palette.darkGrey}`,
    width: "90%",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 600,
    margin: "auto",
    borderRadius: theme.radiusBig,
  },
  contactRoot: screen => ({
    display: "flex",
    justifyContent: screen ? "flex-start" : "space-evenly",
    alignItems: "flex-start",
    textAlign: "left",
    flexWrap: "wrap",
    marginTop: theme.gutterBig * 6,
  }),
  innerBox: {
    marginTop: theme.gutterBig * 2,
    padding: theme.gutterBig,
    maxHeight: 220,
    minWidth: 325,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    ...pageSetting.text2,
  },
}

export default Contact
